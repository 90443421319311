import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import clsx from "clsx";

type Props = {
  user: any;
  width?: any;
  height?: any;
  initials?: any;
  gender?: any;
  size?: any;
  symbol?: any;
  active?: boolean;
  onClick?: any;
  primeiroUltimoNome?: any;
};

const UserPhoto: React.FC<Props> = ({
  user,
  width,
  height,
  initials,
  gender,
  size,
  symbol,
  active,
  onClick,
  primeiroUltimoNome,
}) => {
  const [source, setSource] = useState<any>();
  const [image, setImage] = useState<any>(false);

  return (
    <>
      {image ? (
        <img
          src={source}
          width={width ? width : "auto"}
          height={height ? height : "auto"}
          style={{ objectFit: "fill" }}
          className="rounded"
          onClick={() => {
            onClick(user);
          }}
        />
      ) : (
        <div
          className={clsx("symbol ", symbol ? symbol : "symbol-30px")}
          onClick={() => {
            if (onClick) {
              onClick(user);
            }
          }}
          style={{
            border: active ? "3px solid black" : "",
          }}
        >
          <span
            className={clsx(" symbol-label text-white", size ? size : "fs-5")}
            style={{
              backgroundColor: "#000000",
            }}
          >
            {initials}
          </span>
          <p>
            {primeiroUltimoNome
              ? primeiroUltimoNome
              : user.nome_completo
              ? user.nome_completo
              : ""}
          </p>
        </div>
      )}
    </>
  );
};

export default UserPhoto;
