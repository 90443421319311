import React, { Suspense, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { WithChildren } from "../components/_metronic/helpers";
import TopBarProgress from "react-topbar-progress-indicator";
import Error404 from "../components/Errors/404";

//PAGES
const Home = React.lazy(() => import("../pages/Home/Home"));

const Maintenance_Users = React.lazy(
  () => import("../pages/Company/Users/Users")
);

const Maintenance_User = React.lazy(
  () => import("../pages/Company/Users/User")
);

const Sectors = React.lazy(() => import("../pages/Company/Sectors/AllTabs"));

const Profile = React.lazy(() => import("../pages/Profile/Profile"));

const Access = React.lazy(() => import("../pages/Company/Access/Access"));

const Articles = React.lazy(() => import("../pages/Articles/Sync"));

const Configurations = React.lazy(
  () => import("../pages/Company/Configurations/Configurations")
);

const MenuGroups = React.lazy(
  () => import("../pages/Company/MenuGroups/MenuGroups")
);

//ROUTES
const Rotas = React.lazy(() => import("../pages/Company/Routes/Routes"));

const PrivateRoutes: React.FC = () => {
  const menus = useSelector((state: any) => state.menus.menus);

  const menusCopy = useMemo(() => {
    if (menus) {
      let copy = [...menus];

      for (let i = 0; i < copy.length; i++) {
        if (copy[i].children) {
          copy.push(...copy[i].children);
        }
      }

      return copy;
    } else {
      return [];
    }
  }, [menus]);

  function verifyAccess(id: number, Element: any) {
    return (
      <SuspensedView>
        {menusCopy.some((menu: any) => menu.id == id) ? (
          <Element />
        ) : (
          <Error404 />
        )}
      </SuspensedView>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SuspensedView>
            <Home />
          </SuspensedView>
        }
      />

      <Route
        path="/home"
        element={
          <SuspensedView>
            <Home />
          </SuspensedView>
        }
      />
      <Route
        path="/profile"
        element={
          <SuspensedView>
            <Profile />
          </SuspensedView>
        }
      />

      <Route
        path="/company/users"
        element={verifyAccess(2, Maintenance_Users)}
      />

      <Route
        path="/company/users/:id"
        element={verifyAccess(2, Maintenance_User)}
      />

      <Route path="/company/sectors" element={verifyAccess(2, Sectors)} />

      <Route path="/company/permissions" element={verifyAccess(3, Access)} />

      <Route
        path="/company/group-menus"
        element={verifyAccess(6, MenuGroups)}
      />

      <Route
        path="/company/configurations"
        element={verifyAccess(5, Configurations)}
      />

      <Route path="/company/routes" element={verifyAccess(7, Rotas)} />

      <Route path="/articles/sync" element={verifyAccess(9, Articles)} />

      <Route path="/login" element={<Navigate to="/home" />} />

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

const SuspensedView: React.FC<WithChildren> = ({ children }) => {
  TopBarProgress.config({
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default PrivateRoutes;
